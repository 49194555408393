<template>
    <div class="container content-width">
        <div class="block"></div>

        <div class="ts-measure ts-measure-center">
            <h1 class="page-title">{{ $t("title.cookie_policy") }}</h1>
        </div>

        <Clause
            class="no-border"
            :heading="$t('clauses.clause01.heading')"
            :html="$t('clauses.clause01.html')"
        />

        <Clause
            class="no-border"
            :heading="$t('clauses.clause02.heading')"
            :html="$t('clauses.clause02.html')"
        />

        <Clause
            class="no-border"
            :heading="$t('clauses.clause03.heading')"
            :html="$t('clauses.clause03.html')"
        />

        <Clause
            class="no-border"
            :heading="$t('clauses.clause04.heading')"
            :html="$t('clauses.clause04.html')"
        />

        <Clause
            class="no-border"
            :heading="$t('clauses.clause05.heading')"
            :html="$t('clauses.clause05.html')"
        />
    </div>
</template>
<script>
import Clause from "@/components/Clause"

export default {
    components: {
        Clause,
    },
    meta() {
        return this.$generateMeta({
            title: this.$i18n.t("meta.cookie_policy.title"),
            description: this.$i18n.t("meta.cookie_policy.description"),
        })
    },
    data() {
        return {
            imageToggle: "balance",
        }
    },
    i18n: {
        messages: {
            en: {
                clauses: {
                    clause01: {
                        heading: "",
                        html: `
                    <p class="ts-quiet">Welcome to storkmobile.com, as applicable (the "Site"). The Site is owned and operated by ROKKODE INC. ("We", "us", "our", "we"). The Site uses various types of cookies which help us administer and operate it, as described herein.</p>
                    <p class="ts-quiet">By continuing to use our Site and services including app.storkmobile.com (the “App”), you are agreeing to the use of cookies and similar technologies for the purpose we describe in this Cookie Policy. If you do not accept the use of cookies and similar technologies, please restrain yourself from using our Site.</p>
                    `,
                    },
                    clause02: {
                        heading: "What Are Cookies",
                        html: `
                    <p class="ts-quiet">Cookies are small text files sent by a web server to your browser when you access the Site. Different types of cookies may be used for different purposes, such as to -</p>
                    <ul class="ts-quiet">
                        <li>Collect statistical information about your use of the Site;</li>
                        <li>Save you the need to re-enter your login information on the Site;</li>
                        <li>Remember your actions and preferences on the Site over a period of time, so you don't have to keep re-entering them whenever you come back to the Site; and</li>
                        <li>Collect behavioral information about your surfing on other sites which then help advertisers deliver ads that are more relevant to you.</li>
                    </ul>
                    <p class="ts-quiet">Cookies are classified into two major categories: session cookies and persistent cookies. Session cookies are temporarily stored on your device while you are visiting a given site. They are deleted once you leave the site. Persistent cookies, on the other hand, remain on your device for a more extended period of time specified in the cookie file even after you leave the site. This type of cookie remains available for retrieval by the web server when you return to visit the site.</p>
                    <p class="ts-quiet">Cookies are further classified into "first party cookies" and "third party cookies". First party cookies are those sent to your browser by the server of the site you are visiting. Third party cookies are those sent to your browser by servers other than of the site you are visiting (e.g., the server of an advertising network to which the site you are visiting is subscribed).</p>
                    `,
                    },
                    clause03: {
                        heading: "How We Use Cookies",
                        html: `
                    <p class="ts-quiet">We use first party cookies for Site analytics purposes, such as collecting information related to your activities on our Site, including your session durations, the Site content you accessed and your interactions and user-interface clicks with respect to such content, the Internet protocol (IP) address of the device from which you accessed our Site, the browser you used, its version and language (i.e. locale) settings, and the approximate geographical area from which you accessed the Site.</p>
                    <p class="ts-quiet">We also use third party cookies for payment solution.</p>
                    `,
                    },
                    clause04: {
                        heading: "Types of Cookies We Use",
                        html: `
                    <h4>Technical Cookies:</h4>
                    <p class="ts-quiet">These cookies are necessary to enable you to move around our Site and use its features. These cookies also allow our Site to remember your previous action within the same browsing session. We also use cookies from Stripe for user authentication, fraud detection, fraud prevention.</p>
                    <p class="ts-quiet">A full disclosure of Stripe's use of cookies, including persistent cookies, is documented online at <a class="ts-underline" href="https://stripe.com/cookies-policy/legal" target="_blank">https://stripe.com/cookies-policy/legal</a>.</p>
                    <h4>Analytical Cookies:</h4>
                    <p class="ts-quiet">We use cookies from Google Analytics to obtain web analytics and intelligence about our Site. Google Analytics uses programming code to collect information about your interaction with our Site, such as the pages you visit, the links you click on and how long you are on our Site. Google Analytics does not store any personal identifiable information.</p>
                    <p class="ts-quiet">For more information on how Google Analytics collects and use information on our behalf, please go to <a class="ts-underline" href="http://www.google.com/intl/en/policies/privacy/" target="_blank">http://www.google.com/intl/en/policies/privacy/</a> and <a class="ts-underline" href="http://www.google.com/analytics/terms/us.html" target="_blank">http://www.google.com/analytics/terms/us.html</a></p>
                    <h4>Functionality Cookies:</h4>
                    <p class="ts-quiet">These cookies allow our Site to remember choices you make when you visit our Site in order to provide enhanced features as well as more personalized contents to you. The types of information collected by Functionality Cookies are, language preference, and the region you are located in.</p>
                    `,
                    },
                    clause05: {
                        heading: "How To Control Cookies",
                        html: `
                    <p class="ts-quiet">If you want to restrict or block the cookies that are set by our Site, or any other site, you can do so through your browser setting. The ‘Help’ function in your browser should explain how. Please, however, bear in mind that disabling cookies may affect your user experience on the Site.</p>
                    `,
                    },
                },
            },
        },
    },
}
</script>
